@import "../../global.scss";

.privacy_wrapper {

    display: grid;
    grid-template-columns: 1fr $width1 1fr;
    grid-template-rows: auto 1fr;
    min-height: 100vh;
    max-width: 100vw;

    @include smallDesktop {
        grid-template-columns: 1fr $width2 1fr;
    }

    @include mobile {
        grid-template-columns: 1fr $width3 1fr;
        height: auto;
    }

    @include mobile2 {
        grid-template-columns: 1fr auto 1fr;
        height: auto;
    }

    .privacy_content  {
        grid-column: 2/3;
        grid-row: 1/-1;
        margin-top: 50px;
        font-family: Roboto;

        h1 {
            font-size: 32px;
            font-weight: 600;
        }

        h2 {
            font-size: 24px;
            font-weight: 600;
        }
    }

}