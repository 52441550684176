.bottom_privacy {
    text-align:center;
    margin-bottom: 40px;
    a {
        text-decoration: none;
        font-family: Roboto;
        color: white;
        font-size: 12px;
        font-weight: 500;
    }
}