.banner_title {
    text-align: center;
    font-family: Roboto;
    color: white;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: 1px;
    p {

    }
}