.socials {
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(255, 255, 255);
        border-radius: 100%;
        height: 50px;
        width: 50px;
        //border: solid 1px black;
        margin-left: 5px;
        margin-right: 5px;

        img {
            width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
            z-index: 4;
            width: 40px;
            height: 40px;
        }

        
    }
}

.socials_text {
    text-align: center;
    font-family: Roboto;
    color: gold;
    font-weight: 500;
    font-size: 22px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0.055);
    margin-top: 40px;
}