

.banner_wrapper {
    display: flex;
    justify-content: center;

    .banner {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        width: 350px;
        height: 100px;
        justify-content: center;
        align-items: center;
        //margin-top: 15px;
        margin-bottom: 15px;


        a {
            grid-column: 1/-1;
            grid-row: 1/-1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
            text-decoration: none;
            color: white;
            font-family: Roboto;
            font-size: 38px;
            font-weight: 500;
            letter-spacing: 1px;
            z-index: 3;
            border: 3px solid white;
        }

        .banner_filter {
            grid-column: 1/-1;
            grid-row: 1/-1;
            display: flex;
            width: 100%;
            height: 100%;
            background-color: rgba(27, 27, 27, 0.534);
            z-index: 2;
        }

        img {
            grid-column: 1/-1;
            grid-row: 1/-1;
            max-height: 100%;
            object-fit: cover;
            z-index: 1;
            width: 350px;
            height: 100px;
        }
    }
}